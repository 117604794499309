import React from 'react'
import MOTDHistory from '../MOTDHistory'
import MessageOfTheDayLayout from '../layout'

const MOTDHistoryPage = () => {
  return <MessageOfTheDayLayout>
    <MOTDHistory />
  </MessageOfTheDayLayout>
}

MOTDHistoryPage.propTypes = {

}

MOTDHistoryPage.defaultProps = {

}

export default MOTDHistoryPage
