import React from 'react'
import PrivateLayout from '../../layouts/private'
import MOTDHistoryPage from '../../components/pages/MessageOfTheDay/pages/history'

const MessageOfTheDayHistoryPage = () => {
  return <PrivateLayout><MOTDHistoryPage /></PrivateLayout>
}

MessageOfTheDayHistoryPage.propTypes = {

}

MessageOfTheDayHistoryPage.defaultProps = {

}

export default MessageOfTheDayHistoryPage
